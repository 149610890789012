import { NavLink } from 'react-router-dom';
import "./Pages.css"

function Home() {
  return (
    <div className="home">
		<div className="home-img-space" style={{backgroundImage: "url(./FondoBoda2.jpeg)"}}>
                                                                                        
        <div className="center">                                                  
          <h1 className="title">BODA DE <br/> OLIVIA & HECTOR</h1>                      
                                                                                        
          <a className="text">Seleccion de disfraz</a>                                  
          <br/>                                                                         
          <NavLink to="/Categorias">                                                    
            <button className="body-button"><a>Comenzar</a></button>                    
          </NavLink>                                                                    
        </div>                                                                          

		<div className="home-description">
  			<div className="page-intention">
  			  <h1>Pagina de disfraces</h1>
  			  <a>Esta pagina tiene como intencion coordinar <br/>los disfraces que llevaran los invitados</a>         
 			 </div>
			</div>

		</div>
    </div>
  );
}

export default Home;
